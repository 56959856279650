import { Fragment } from "react";
import { Heading, StyledLink } from "../styles";
import { CUSTOMER_CARE_LINKS } from "../data"; 
// ==============================================================


// ==============================================================
export default function CustomerCareLinks({
  isDark
}) {
  return <Fragment>
      <Heading>Customer Care</Heading>

      <StyledLink isDark={isDark} href="/">Privacy Policy</StyledLink>
      <StyledLink isDark={isDark} href="/">Terms & Conditions</StyledLink>
    </Fragment>;
}