import { Fragment } from "react";
import { Heading, StyledLink } from "../styles";
import { ABOUT_LINKS } from "../data"; 
// ==============================================================


// ==============================================================
export default function AboutLinks({
  isDark
}) {
  return <Fragment>
      <Heading>About Us</Heading>

      <div>
       

        <StyledLink isDark={isDark} href="/">Privacy Policy</StyledLink>
        <StyledLink isDark={isDark} href="/">Terms & Conditions</StyledLink>
      </div>
    </Fragment>;
}