import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container"; 
// LOCAL CUSTOM COMPONENT

import AboutLinks from "./components/about-links";
import SocialLinks from "./components/social-links";
import CustomerCareLinks from "./components/customer-care-links"; 
// GLOBAL CUSTOM COMPONENTS

import LazyImage from "components/LazyImage";
import { Paragraph } from "components/Typography";
import FlexBetween from "components/flex-box/flex-between"; 
// STYLED COMPONENT

import { Heading } from "./styles";
export default function Footer4() {
  return <Box component="footer" bgcolor="white" pt={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item lg={4} md={4} sm={6} xs={12}>
            <Box maxWidth={100}>
              <LazyImage src={require("../../../public/assets/images/haha-logo.png")} alt="logo" />
            </Box>

            <Paragraph mb={2.5} maxWidth={{
            xl: 400
          }}>
              Welcome to Haha Price, the ultimate free tool designed to help you find all the best half-price products from your favorite stores! Whether you're shopping for groceries, household items, or everyday essentials, Haha Price helps you save money by curating a wide selection of discounted items in one place. Stop wasting time searching for deals—let us bring the best half-price offers to you so you can shop smarter and stretch your dollar further.
              </Paragraph>
              <Paragraph mb={2.5} maxWidth={{
            xl: 400
          }}>
              Start saving today with Haha Price, because every penny counts!
            </Paragraph>
          </Grid>

          {
          /* ABOUT US LINKS */
        }
          <Grid item lg={2} md={2} sm={6} xs={12}>
            
          </Grid>

          {
          /* CUSTOMER CARE LINKS */
        }
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <CustomerCareLinks isDark />
          </Grid>

          {
          /* CONTACT INFORMATION */
        }
          <Grid item lg={3} md={3} sm={6} xs={12}>
            <Heading>Contact Us</Heading>

            <Paragraph py={0.6}>
              Adelaide, South Austrlia
            </Paragraph>

            <Paragraph py={0.6}>Email: takaokanji@gmail.com</Paragraph>
          </Grid>
        </Grid>

        <Box component={Divider} mt={{
        md: 8,
        xs: 3
      }} />

        <FlexBetween pt={2} pb={{
        sm: 10,
        md: 2
      }}>
          <Paragraph>© 2024 By Pgyer.</Paragraph>
          <SocialLinks variant="dark" />
        </FlexBetween>
      </Container>
    </Box>;
}