
// CUSTOM ICON COMPONENTS
import Google from "icons/Google";
import Twitter from "icons/Twitter";
import Youtube from "icons/Youtube";
import Facebook from "icons/Facebook";
import Instagram from "icons/Instagram";
import PlayStore from "icons/PlayStore";
import AppleStore from "icons/AppleStore";
export const ABOUT_LINKS = ["Terms & Conditions", "Privacy Policy"];
export const CUSTOMER_CARE_LINKS = ["Help Center", "Terms & Conditions", "Privacy Policy"];
export const SOCIAL_ICON_LINKS = [{
  Icon: PlayStore,
  url: "https://showsalary.com"
}];
export const PLAY_APP_STORE_DATA = [{
  url: "/",
  icon: PlayStore,
  title: "Google Play",
  subtitle: "Get it on"
}, {
  url: "/",
  icon: AppleStore,
  title: "App Store",
  subtitle: "Download on the"
}];